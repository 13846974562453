import * as React from 'react';
import { ReactNode } from 'react';

import './BlueBox.scss';

interface Props {
  text: ReactNode | string;
  className?: string;
}

export const BlueBox = ({ text, className }: Props) => (
  <div
    className={[
      'col-6 col-lg-4 col-xl-2',
      className,
    ].join(' ')}
  >
    <article className="blue-box">
      <div className="blue-box__content">
        {text}
      </div>
    </article>
  </div>
);
