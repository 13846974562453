import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { SEO } from 'src/components/SEO';
import { Layout } from 'src/components/Layout';
import { PageTitle } from 'src/components/PageTitle';
import { BlueBox } from 'src/components/BlueBox';

const Headhunting = () => (
  <Layout className="recruitment">
    <SEO title="Headhunting" />
    <div className="container pt-2">
      <PageTitle className="mb-2 mb-lg-8" title='Headhunting' />
      <h2 className="mb-3">How do you know you need headhunting?</h2>
      <div className="row mb-lg-4 justify-content-xl-between">
        <div className="d-none d-xl-block col-6 col-lg-4 col-xl-2">
          <StaticImage
            src="../assets/images/recruitment/showing.png"
            alt="Graphic"
            layout="fullWidth"
          />
        </div>
        <BlueBox text={<span>Looking for an <strong>executive colleague</strong></span>} />
        <BlueBox text={<span>Internal talent pool is <strong>not sufficient</strong></span>} />
        <BlueBox text={<span>Team<br /> <strong>diversity</strong></span>} />
        <BlueBox text={<span><strong>Specific expertise</strong> needs</span>} />
      </div>
    </div>
    <div className="container pb-2 pb-lg-6">
      <h2 className="mb-3">Steps of the cherry picking process</h2>
      <p className="mb-3 text-justify">
        The whole cooperation starts with the thorough definition of the positions
        that need to be hired. As a next step, we will provide you with a list of
        vetted candidates that match your desired level of knowledge and experience
        for the particular role. Afterwards we will jointly decide on the shortlist.
        Each and every step of the process is transparent and managed according to
        the agreed milestones.
      </p>
      <div className="row">
        <div className="col-12 col-md-6 mb-4">
          <div className="white-box d-flex flex-column">
            <StaticImage
              className="mx-auto mb-3"
              src="../assets/images/headhunting/360.png"
              alt="Graphic"
            />
            <h3 className="mb-3">360° role definition</h3>
            <ul className="white-box__list">
              <li>Close cooperation with the hiring manager and all stakeholders of the position</li>
              <li>Definition of the critical must have criteria</li>
              <li>Understanding of the project, goals and KPIs of the role</li>
              <li>3 performance based criteria</li>
            </ul>
          </div>
        </div>
        <div className="col-12 col-md-6 mb-4">
          <div className="white-box d-flex flex-column">
            <StaticImage
              className="mx-auto mb-3"
              src="../assets/images/headhunting/research.png"
              alt="Graphic"
            />
            <h3 className="mb-3">Market research & market intelligence</h3>
            <ul className="white-box__list">
              <li>Funnel approach</li>
              <li>
                Selected candidates presented in a <strong>long list </strong>
                with valuable insights and agency rating
              </li>
              <li>
                Based on the market intelligence, collected references,
                competency based criteria and your personal preferences
                we together <strong>short list</strong> the best candidates
              </li>
            </ul>
          </div>
        </div>
        <div className="col-12 col-md-6 mb-4">
          <div className="white-box d-flex flex-column">
            <StaticImage
              className="mx-auto mb-3"
              src="../assets/images/headhunting/fit.png"
              alt="Graphic"
            />
            <h3 className="mb-3">Best fit candidate</h3>
            <ul className="white-box__list">
              <li>Project presented only to short listed candidates</li>
              <li>
                With utmost personal approach we convert maximum number
                of short listed candidates into interviews
              </li>
              <li>
                Our recruiter participates in all interviews and provides
                candidates with guidance and yourself with feedback
              </li>
            </ul>
          </div>
        </div>
        <div className="col-12 col-md-6 mb-4">
          <div className="white-box d-flex flex-column">
            <StaticImage
              className="mx-auto mb-3"
              src="../assets/images/headhunting/offer.png"
              alt="Graphic"
            />
            <h3 className="mb-3">Job offer</h3>
            <ul className="white-box__list">
              <li>Detailed understanding of candidates expectations</li>
              <li>We support you in designing and positioning of the job offer to assure the acceptance</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default Headhunting;
